globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"68b6a71357b7e068ccf81bf8702b541f72446eec"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';

// TODO: Remove this workaround - use NEXT_PUBLIC variables when they will be available in all environment.
const publicEnvironmentVariables = window.PUBLIC_ENV;

Sentry.init({
  debug: false,
  dsn: publicEnvironmentVariables?.sentryDSN,
  enabled: publicEnvironmentVariables?.appEnvironment !== 'LOCAL',
  environment: publicEnvironmentVariables?.appEnvironment,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      blockAllMedia: true,
      maskAllText: false,
    }),
  ],
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0.05,
  tracesSampleRate: 0.05,
});
